import React, { createContext, useState, useContext, useEffect } from 'react';

// Create AuthContext
const AuthContext = createContext();

// Custom hook to use AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setAuth(token);
        }
        setLoading(false); // Set loading to false after checking for token
    }, []);

    const login = (token) => {
        setAuth(token);
        localStorage.setItem('token', token);
    };

    const logout = () => {
        setAuth(null);
        localStorage.removeItem('token');
    };

    const value = {
        auth,
        login,
        logout,
    };

    if (loading) {
        return <div>Loading...</div>; // Render a loading message or spinner while checking for token
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
