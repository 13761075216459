// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ChatBox.css */


.attach-button {
    background-color: #424242;
    border: none;
    color: #fff;
    font-size: 10px;
    margin-right: 10px;
}

.chatbox-input {
    background-color: transparent;
    border: none;
    color: #141414;
    flex-grow: 1;
    outline: none;
    height: 2.5rem;

}

.chatbox-input::placeholder {
    color: #353535;
}

.chatbox-file-input {
    display: none;
}


.response-container {
    margin: 20px 0;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.response-container h3 {
    margin-top: 0;
}

.response-container pre {
    white-space: pre-wrap; /* Ensures that long lines wrap inside the container */
    word-wrap: break-word; /* Breaks words if they are too long */
}

.response-container details {
    width: 100%;
    overflow: auto;
}

/* Responsive styles for the <ul> element */
.response-container ul {
    padding: 0;
    list-style-type: none;
}

.response-container li {
    padding: 0.5rem 0;
}

.response-container a {
    word-break: break-word; /* Ensure long URLs break correctly */
}`, "",{"version":3,"sources":["webpack://./src/components/ChatBox.css"],"names":[],"mappings":"AAAA,+BAA+B;;;AAG/B;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,cAAc;IACd,YAAY;IACZ,aAAa;IACb,cAAc;;AAElB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;;AAGA;IACI,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB,EAAE,sDAAsD;IAC7E,qBAAqB,EAAE,sCAAsC;AACjE;;AAEA;IACI,WAAW;IACX,cAAc;AAClB;;AAEA,2CAA2C;AAC3C;IACI,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,sBAAsB,EAAE,qCAAqC;AACjE","sourcesContent":["/* src/components/ChatBox.css */\n\n\n.attach-button {\n    background-color: #424242;\n    border: none;\n    color: #fff;\n    font-size: 10px;\n    margin-right: 10px;\n}\n\n.chatbox-input {\n    background-color: transparent;\n    border: none;\n    color: #141414;\n    flex-grow: 1;\n    outline: none;\n    height: 2.5rem;\n\n}\n\n.chatbox-input::placeholder {\n    color: #353535;\n}\n\n.chatbox-file-input {\n    display: none;\n}\n\n\n.response-container {\n    margin: 20px 0;\n    padding: 10px;\n    background-color: #f9f9f9;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n}\n\n.response-container h3 {\n    margin-top: 0;\n}\n\n.response-container pre {\n    white-space: pre-wrap; /* Ensures that long lines wrap inside the container */\n    word-wrap: break-word; /* Breaks words if they are too long */\n}\n\n.response-container details {\n    width: 100%;\n    overflow: auto;\n}\n\n/* Responsive styles for the <ul> element */\n.response-container ul {\n    padding: 0;\n    list-style-type: none;\n}\n\n.response-container li {\n    padding: 0.5rem 0;\n}\n\n.response-container a {\n    word-break: break-word; /* Ensure long URLs break correctly */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
