import React, { useState } from 'react';
import axios from 'axios';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://chattnfd.app/auth/forgot-password/', {
                email: email,
            });
            setMessage(response.data.message);
            setError('');
        } catch (err) {
            console.error(err);
            setError('Error sending reset email. Please try again.');
            setMessage('');
        }
    };

    return (
        <div className="container">
        <div className="row justify-content-center align-items-start">
            <div className="col-lg-6 specialbox">
                            <h1>Forgot Password</h1>
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email address</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                <button type="submit" className='btn btn-primary'>Send Reset Link</button>
            </form>
            {message && <div>{message}</div>}
            {error && <div>{error}</div>}
        </div>
        </div>
        </div>
    );
};

export default ForgotPassword;
