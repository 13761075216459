// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ResetPassword';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import Chatbot from './pages/Chabot';
import DisclaimerPage from './pages/DisclaimerPage';
import DatabasePage from './pages/DatabasePage';
import Logout from './pages/Logout';

function App() {
  return (
    <AuthProvider>
    <Router>
      <div className="App">
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/" element={<Home />} />


          <Route 
                        path="/database" 
                        element={
                            <PrivateRoute>
                                <DatabasePage />
                            </PrivateRoute>
                        } 
                    />
                     <Route 
                        path="/disclaimer" 
                        element={
                            <PrivateRoute>
                                <DisclaimerPage />
                            </PrivateRoute>
                        } 
                    />
                    

          <Route 
                        path="/dashboard" 
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        } 
                    />
        </Routes>
        
      </div>
    </Router>
    </AuthProvider>

  );
}

export default App;
