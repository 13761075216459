import React from 'react';

const Database = () => {
  return (
    <div className="container col-md-6 mt-5">
      <div className="card">
        <div className="card-body">
          <h1 className="card-title">TNFD Recommendations and Resources</h1>
          <p className="card-text">
            Here are some key resources related to the Taskforce on Nature-related Financial Disclosures (TNFD) recommendations:
          </p>
          <ul className="list-group">
          <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
            Taskforce on Nature-related Financial Disclosures (TNFD) Recommendations
        </a>
    </li>
    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
            Executive Summary of the TNFD Recommendations
        </a>
    </li>
    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
            Guidance on the identification and assessment of nature-related issues: the LEAP approach
        </a>
    </li>
    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
            Guidance on scenario analysis
        </a>
    </li>
    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
            Guidance on engagement with Indigenous Peoples, Local Communities and affected stakeholders
        </a>
    </li>
    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
            Guidance for corporates on science-based targets for nature
        </a>
    </li>
    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
            Glossary
        </a>
    </li>
    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
            Getting started with adoption of the TNFD Recommendations
        </a>
    </li>
    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
            Guidance on biomes
        </a>
    </li>
    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
            Findings of a high-level scoping study exploring the case for a global nature-related public data facility
        </a>
    </li>
    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
            Discussion paper on proposed approach to value chains
        </a>
    </li>
    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
        Additional sector guidance – Metals and mining
        </a>
    </li>

    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
        Additional sector guidance – Electric utilities and power generators
        </a>
    </li>

    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
        Additional guidance for financial institutions
        </a>
    </li>

    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
        Additional sector guidance – Chemicals
        </a>
    </li>

    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
        Additional sector guidance – Food and agriculture
        </a>
    </li>
    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
        Additional sector guidance – Oil and gas
        </a>
    </li>

    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
        Additional sector guidance – Forestry, pulp and paper
        </a>
    </li>

    
    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
        Additional sector guidance – Aquaculture
        </a>
    </li>


    <li className="list-group-item">
        <a href="https://tnfd.global/tnfd-publications" target="_blank" rel="noopener noreferrer">
        Additional sector guidance – Biotechnology and pharmaceuticals
        </a>
    </li>





          </ul>
        </div>
      </div>
    </div>
  );
};

export default Database;
