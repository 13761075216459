import React, { useState } from 'react';
import './ChatBox.css'; // Custom styling
import { Oval } from 'react-loader-spinner';


const AskBox = () => {
    const [question, setQuestion] = useState('');
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            question: question,
            org: 'TNFD', // Constant org value
            similarity_top_k: 10 // Constant similarity_top_k value
        };
        setLoading(true);
        try {
            const response = await fetch('https://chattnfd.app/qa/query', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`Failed to send message: ${response.statusText}`);
            }

            const responseData = await response.json();
            console.log(responseData);

            // Update the response state
            setResponse(responseData["Response from simple LLM prompting with references"]);

            // Clear the input after sending
            setQuestion('');
        } catch (err) {
            console.error('Error:', err.message || err);
        }
        finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    return (
        <div className="chatbox-container align-items-center">
            <div className='row'>
                <h2>Ask Questions</h2>
            </div>
            {response && (
                <div className="response-container col-">
                    <h3>Response</h3>
                    <pre>{response}</pre>
                </div>
            )}

{loading && (
                <div className="loading-container">
                    <Oval
                        height={50}
                        width={50}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            )}

            <form className="chatbox-input-group" onSubmit={handleSubmit}>
                <input
                    type="text"
                    className="form-control chatbox-input"
                    placeholder="Ask"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
            </form>
        </div>
    );
};

export default AskBox;
